import "animate.css";
import { Trn } from "./components/trn/Trn";
import { useEffect, useState } from "react";
import { TopBar } from "./components/TopBar";
import { createClient } from "@hey-api/client-fetch";
import { NewTransfer } from "./components/NewTransfer";
import { PageNotFound } from "./components/PageNotFound";
import { Homepage } from "./components/homepage/Homepage";
import { handleCookie, handleError } from "./utils/functions";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { UserService, User } from "./api-client";

function App() {
  const [fbaTransfer, setFbaTransfer] = useState<any>([]);
  const [userInfo, setUserInfo] = useState<User | undefined>();

  createClient({
    baseUrl: process.env.REACT_APP_API_URL as string,
  });

  const getUser = async () => {
    try {
      const response = await UserService.getUserSelf();

      setUserInfo(response.data);
    } catch (error: any) {
      handleError(error.message);
    }
  };
  useEffect(() => {
    handleCookie();
    getUser();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<TopBar userInfo={userInfo} />}>
            <Route path="/" element={<Homepage userInfo={userInfo} />} />
            <Route
              path="/mp/:mpId/trn/:trnId"
              element={<Trn userInfo={userInfo} />}
            />
            <Route
              path="/new-transfer"
              element={
                <NewTransfer
                  fbaTransfer={fbaTransfer}
                  setFbaTransfer={setFbaTransfer}
                  userInfo={userInfo}
                />
              }
            />
          </Route>
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
