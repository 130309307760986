import { useSkusStore } from "../../store/tempTransfer";
import { useState } from "react";

export const InputComponent = ({
  id,
  quantity,
}: {
  id: string;
  quantity: number;
}) => {
  const [error, setError] = useState<boolean>(false);
  const { skus, setSkus } = useSkusStore();

  const [quantityInput, setQuantityInput] = useState<string>(
    quantity ? String(quantity) : ""
  );

  const addQuantity = (value: string) => {
    let numericValue = value.replace(/[^\d.]/g, "");

    const parts = numericValue.split(".");
    if (parts.length > 1) {
      numericValue = parts[0] + "." + parts[1].slice(0, 2);
    }

    setQuantityInput(numericValue);

    const quantity = parseFloat(numericValue);

    if (isNaN(quantity)) {
      setError(true);
    } else {
      setError(false);
    }

    const newArray = [...skus];
    const index = newArray.findIndex((product: any) => product.mpSku === id);

    newArray[index].shipQuantity = quantity;

    setSkus(newArray);
  };

  return (
    <div className="flex justify-center -my-0.5">
      <input
        onChange={(e) => {
          if (e.target.value !== "e") {
            addQuantity(e.target.value);
          }
        }}
        type="text"
        value={quantityInput}
        className={`w-[90%] border-[1px] rounded shadow p-1 focus:outline-none h-[26px] ${
          error
            ? "border-red-500 text-red-500 border-[1.5px]"
            : "border-gray-300"
        }`}
      />
    </div>
  );
};
