import { useState, useMemo, useCallback, FC, useEffect } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { useTempTransferStore } from "../../store/tempTransfer";
import ReactLoading from "react-loading";
import { useTable, Column } from "react-table";
import { AddProductsInterface, ShipmentSkuType } from "../../type/stepsType";
import { handleError } from "../../utils/functions";
import { MarketplacesService } from "../../api-client";

interface TableDataRow {
  sku: string;
  quantity: number | null;
}

export const PasteInbound: FC<AddProductsInterface> = ({
  setSkus,
  skus,
  setModalOpen,
}) => {
  const [data, setData] = useState<TableDataRow[]>([
    { sku: "", quantity: null },
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const { tempTransfer, setTempTransfer } = useTempTransferStore();
  const columns: Column<TableDataRow>[] = useMemo(
    () => [
      { Header: "SKU", accessor: "sku" },
      { Header: "Quantity", accessor: "quantity" },
    ],
    []
  );
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());

  const [dragStartIndex, setDragStartIndex] = useState<number | null>(null);

  const onMouseDown = (rowIndex: number) => {
    setDragStartIndex(rowIndex);
    setSelectedRows(new Set([rowIndex]));
  };

  const onMouseOver = (rowIndex: number) => {
    if (dragStartIndex !== null) {
      const minIndex = Math.min(dragStartIndex, rowIndex);
      const maxIndex = Math.max(dragStartIndex, rowIndex);
      const newSelectedRows = new Set<number>();

      for (let i = minIndex; i <= maxIndex; i++) {
        newSelectedRows.add(i);
      }

      setSelectedRows(newSelectedRows);
    }
  };
  const onMouseUp = () => {
    setDragStartIndex(null);
  };
  const deleteSelectedRows = useCallback(() => {
    const newData = data.filter((_, index) => !selectedRows.has(index));
    setData(newData);
    setSelectedRows(new Set());
  }, [data, selectedRows]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const handleAddRow = () => {
    setData([...data, { sku: "", quantity: null }]);
  };

  const handleRemoveRow = (rowIndex: number) => {
    const newData = data.filter((_, index) => index !== rowIndex);
    setData(newData);
  };

  const handleChange = (rowIndex: number, columnId: string, value: string) => {
    const newData = data.map((row, index) =>
      index === rowIndex ? { ...row, [columnId]: value } : row
    );
    setData(newData);
  };

  const handlePaste = (
    e: React.ClipboardEvent<HTMLInputElement>,
    rowIndex: number
  ) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").split(/\r\n|\n/);
    const updatedData = [...data];

    pastedData.forEach((pastedRow, index) => {
      const [sku, quantity] = pastedRow.split("\t");
      if (sku) {
        if (rowIndex + index < updatedData.length) {
          updatedData[rowIndex + index] = {
            sku,
            quantity: Number(quantity) || null,
          };
        } else {
          updatedData.push({ sku, quantity: Number(quantity) });
        }
      }
    });
    updatedData.push({ sku: "", quantity: null });
    setData(updatedData);
  };

  const addProducts = async () => {
    if (!tempTransfer?.shipmentInfo) return;
    setLoading(true);
    // remove duplicate sku
    const uniqueSKUs = new Set();
    const filteredRows = rows.filter((row) => {
      const sku = row.original.sku;
      if (sku && !uniqueSKUs.has(sku)) {
        uniqueSKUs.add(sku);
        return true;
      } else {
        return false;
      }
    });

    // check all sku have a quantity
    for (const row of filteredRows) {
      const { sku, quantity } = row.original;

      if (quantity === null || quantity < 0) {
        handleError({
          error: `Quantity is missing for SKU at row ${row.index + 1}`,
        });
        setLoading(false);
        return;
      }
    }

    const updatedSkus: ShipmentSkuType[] = [...skus];
    const SkusSearch = Array.from(uniqueSKUs).join(",");

    try {
      const { marketPlaceId } = tempTransfer.shipmentInfo.shipDestination;
      const { warehouseId } = tempTransfer.shipmentInfo.shipFrom;
      if (!marketPlaceId) {
        handleError({ error: "Marketplace or warehouse is missing" });
        return;
      }
      const body = {
        includeInventory: warehouseId,
        linked: "LINKED" as "LINKED" | "UNLINKED" | undefined,
        fulfillment: "MARKETPLACE" as
          | "MARKETPLACE"
          | "ANY"
          | "MERCHANT"
          | undefined,
        search: SkusSearch,
      };

      const response = await MarketplacesService.postMarketplacesMpidListings({
        path: {
          mpId: marketPlaceId,
        },
        body,
      });

      const { data, error } = response;
      if (error) {
        handleError(error);
        return;
      }

      const { results } = response.data;
      if (!results) {
        handleError({ error: "No items found for the provided SKU" });
        setLoading(false);
        return;
      }

      for (let i = 0; i < filteredRows.length; i++) {
        const sku = filteredRows[i].original.sku.trim();

        const matchingMpSkuIndex = results.findIndex(
          (result) => result.mpSku === sku
        );
        if (matchingMpSkuIndex !== -1) {
          // Found a match at index matchingIndex
          updatedSkus.push({
            ...results[matchingMpSkuIndex],
            shipQuantity: filteredRows[i].original.quantity as number,
          });
        } else {
          // No match found check with case-sensitivity

          const matchingMpSkuCIIndex = results.findIndex(
            (result) => result.mpSku?.toLowerCase() === sku.toLowerCase()
          );
          if (matchingMpSkuCIIndex !== -1) {
            updatedSkus.push({
              ...results[matchingMpSkuCIIndex],
              shipQuantity: filteredRows[i].original.quantity as number,
            });
          } else {
            // No match found check with no case-sensitivity

            const matchingLinkedSkuIndex = results.findIndex(
              (result) =>
                result.linkedItem?.item?.sku?.toLowerCase() ===
                sku.toLowerCase()
            );
            if (matchingLinkedSkuIndex !== -1) {
              updatedSkus.push({
                ...results[matchingLinkedSkuIndex],
                shipQuantity: filteredRows[i].original.quantity as number,
              });
            } else {
              // no match found with linkedItemSku
              handleError({
                error: `No item found for sku ${sku} in row ${i + 1} `,
              });
              setLoading(false);
              return;
            }
          }
        }
      }
      setModalOpen(false);
    } catch (error: any) {
      debugger;
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
    setSkus(updatedSkus);

    setLoading(false);
  };

  useEffect(() => {
    const handleGlobalMouseUp = () => {
      setDragStartIndex(null);
    };

    document.addEventListener("mouseup", handleGlobalMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleGlobalMouseUp);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Delete" || event.key === "Backspace") {
        deleteSelectedRows();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [deleteSelectedRows]);

  return (
    <div
      onMouseDown={() => setSelectedRows(new Set())}
      className="fixed w-[100vw] h-[100vh] bg-black bg-opacity-50 top-0 left-0 z-[20]"
    >
      <div className="fixed rounded-md min-w-[300px] min-h-[60vh] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 bg-white px-4 pt-4">
        <div className="flex flex-col justify-between h-[60vh]">
          <div className="max-h-[85%] overflow-x-auto pr-1">
            {/*  <h3 className="text-[25px] mb-2">Paste inbound</h3> */}
            <table {...getTableProps()} className="border border-gray-300">
              <thead className="bg-gray-100">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    <th></th>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                    <th></th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  const isSelected = selectedRows.has(row.index);
                  return (
                    <tr
                      onMouseDown={() => onMouseDown(row.index)}
                      onMouseOver={() => onMouseOver(row.index)}
                      onMouseUp={onMouseUp}
                      {...row.getRowProps()}
                    >
                      <td className="border-gray-300 bg-gray-100 h-full w-full border">
                        <p className="mr-1 text-sm rounded-full text-gray-900 w-6 h-6 flex items-center justify-center">
                          {i + 1}
                        </p>
                      </td>
                      {row.cells.map((cell) => (
                        <td
                          className="border-gray-300 border"
                          {...cell.getCellProps()}
                        >
                          <input
                            type={
                              cell.column.id === "quantity" ? "number" : "text"
                            }
                            className={`peer p-1 pl-2 h-[30px] ${
                              isSelected
                                ? "border border-[#5292f7] bg-[#d8e7fe]"
                                : ""
                            } ${
                              cell.column.id === "quantity"
                                ? "w-[80px]"
                                : "w-[280px]"
                            }`}
                            value={cell.value}
                            onChange={(e) =>
                              handleChange(
                                row.index,
                                cell.column.id,
                                e.target.value
                              )
                            }
                            onPaste={(e) => handlePaste(e, row.index)}
                          />
                        </td>
                      ))}
                      <td className="border-gray-300 border">
                        <button
                          onClick={() => handleRemoveRow(row.index)}
                          className="px-2 text-red-600 hover:text-red-700"
                        >
                          <FaTrash className="shadow-2xl" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="w-full flex items-center justify-center mt-2">
              <button
                onClick={handleAddRow}
                className="p-2 bg-blue-500 hover:bg-blue-600 text-white flex items-center shadow-md gap-1 rounded-full"
              >
                <FaPlus />
              </button>
            </div>
          </div>
          <div className="flex gap-4 justify-between items-center my-4  mx-5">
            <button
              onClick={() => setModalOpen(false)}
              className="p-2 px-3 bg-red-600 hover:bg-red-700 text-white font-semibold rounded-md shadow-md disabled:bg-gray-400 w-[90px] h-[40px]"
            >
              Cancel
            </button>
            <button
              onClick={addProducts}
              className="p-2 px-3 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-md shadow-md disabled:bg-gray-400 w-[90px] h-[40px]"
            >
              {loading ? (
                <div className="flex justify-center">
                  <ReactLoading type="spinningBubbles" height={30} width={30} />
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
