
import { ShipmentTable } from "./ShipmentTable";
import { columnsShipment } from "../../utils/columns";
import { ShipmentWithPackages } from "../../api-client";

export const Shipment = ({ shipment }: { shipment: ShipmentWithPackages }) => {
  return (
    <div className="mb-4">
      <div className="flex flex-col md:flex-row justify-between">
        <div className="pl-4 flex flex-col md:flex-row justify-between w-[90%]">
          <p className="mb-2">
            <span className="font-semibold">Carrier Name: </span>
            {shipment.carrierName?.replaceAll("_", " ")}
          </p>
          {shipment.carrierTracking && (
            <p className="mb-2">
              <span className="font-semibold">Carrier Tracking: </span>
              {shipment.carrierTracking}
            </p>
          )}
          <p className="mb-2">
            <span className="font-semibold">Notification: </span>
            {shipment.receiverNotified ? "Notified" : "Not Notified"}
          </p>
        </div>
      </div>
      <h4 className="text-xl font-semibold my-3">Packages</h4>
      <div className="">
        <ShipmentTable data={shipment.packages} columns={columnsShipment} />
        {/*      {shipment.packages.map((pack: any, packIndex: any) => (
            <div
              key={packIndex}
              className="mb-6 p-5 bg-gray-50 shadow-md rounded-lg w-fit"
            >
              <div className="flex justify-between">
                <h3 className="text-lg font-semibold mb-4">
                  Package No. {pack.number}
                </h3>{" "}
              </div>
              <PackageCard pack={pack} />
            </div>
          ))} */}
      </div>
    </div>
  );
};
