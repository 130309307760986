import {
  Step2Interface,
  ShipmentItemType,
  ColumnType,
  UomType,
  CreatePlan,
} from "../../type/stepsType";
import { useState, FC, useEffect } from "react";
import { useTempTransferStore } from "../../store/tempTransfer";
import ReactLoading from "react-loading";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { DataTable } from "../DataTable";
import { Row } from "react-table";
import { handleError } from "../../utils/functions";
import {
  FbaInboundCreatePlanItemPrepRequest,
  MarketplacesService,
} from "../../api-client";

export const Step2: FC<Step2Interface> = ({ setStep2Completed }) => {
  const { tempTransfer, setTempTransfer } = useTempTransferStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [uoms, setUoms] = useState<UomType[]>();
  const [preferUomId, setPreferUomId] = useState<number>();

  const [labelPrepPreference, setLabelPrepPreference] = useState<
    string | undefined
  >("SELLER_LABEL");

  const sendData = async () => {
    if (
      !tempTransfer ||
      !tempTransfer?.shipmentPrep?.items ||
      !tempTransfer?.shipmentInfo
    )
      return;
    setLoading(true);
    const { marketPlaceId } = tempTransfer.shipmentInfo.shipDestination;
    const { warehouseId } = tempTransfer.shipmentInfo.shipFrom;
    const { shipToCountryCode, items } = tempTransfer.shipmentPrep;
    if (!marketPlaceId || warehouseId === undefined) {
      return;
    }

    const itemsPayload = [];

    try {
      for (let i = 0; i < items.length; i++) {
        const element = items[i];
        if (element.error) {
          handleError({ error: "Please remove the sku with error" });
          setLoading(false);
          return;
        }
        const prepsPayload = [];
        if (
          element.prepInstructions?.length &&
          element.prepInstructions.length > 0
        ) {
          for (const instruction of element.prepInstructions) {
            prepsPayload.push({
              prepInstruction: instruction.name!,
              // @ts-ignore
              prepOwner: instruction.prepOwner!,
            });
          }

          itemsPayload.push({
            quantity: element.shipQuantity!,
            sellerSku: element.sellerSku!,
            preps:
              prepsPayload as unknown as FbaInboundCreatePlanItemPrepRequest[],
          });
        } else {
          itemsPayload.push({
            quantity: element.shipQuantity!,
            sellerSku: element.sellerSku!,
            preps: null,
          });
        }
      }

      const payload = {
        labelPrepPreference: labelPrepPreference as
          | "SELLER_LABEL"
          | "AMAZON_LABEL_ONLY"
          | "AMAZON_LABEL_PREFERRED",
        shipToCountryCode: shipToCountryCode || "",
        shipFromWarehouseId: warehouseId,
        items: itemsPayload,
        preferUomId,
      };
      const response =
        await MarketplacesService.postMarketplacesMpidAmazonFbaInboundCreatePlan(
          { body: payload, path: { mpId: marketPlaceId } }
        );

      if (response.error) {
        const { error, errors } = response.error;
        if (error || errors) {
          handleError(response.error);
          return;
        }
      }

      const newObject = { ...tempTransfer };
      newObject.shipmentPlan = response.data?.results as CreatePlan[];
      setTempTransfer(newObject);
      setStep2Completed(true);
      setLoading(false);
    } catch (error: any) {
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }

      setLoading(false);
      return false;
    }
  };

  const removeSKu = (id: string) => {
    if (tempTransfer && tempTransfer.shipmentPrep?.items) {
      const filteredItem = tempTransfer.shipmentPrep.items.filter(
        (product: any) => product.sellerSku !== id
      );

      setTempTransfer({
        ...tempTransfer,
        shipmentPrep: {
          ...tempTransfer.shipmentPrep,
          items: filteredItem,
        },
        shipmentInfo: tempTransfer.shipmentInfo,
      });
    }
  };

  const handlePrepOwner = (
    prep: string,
    itemIndex: number,
    instructionIndex: number
  ) => {
    if (!tempTransfer?.shipmentPrep?.items) return;

    const transferCopy = JSON.parse(JSON.stringify(tempTransfer));

    const item = transferCopy.shipmentPrep.items[itemIndex];
    if (item) {
      const instruction = item.prepInstructions[instructionIndex];
      if (instruction) {
        instruction.prepOwner = prep;
      }
    }

    setTempTransfer(transferCopy);
  };

  useEffect(() => {
    const getUoms = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}lists/itemUoms`
        );

        setUoms(response.data);
      } catch (error: any) {
        if (error.response) {
          handleError(error.response.data);
        } else {
          handleError(error.message);
        }
      }
    };
    getUoms();
  }, []);

  const columnsStep2React: ColumnType[] = [
    {
      accessor: "sellerSku",
      Header: "SKU",
      minWidth: 400,
    },
    {
      accessor: "barcodeInstruction",
      Header: "Barcode instruction",
      minWidth: 230,
    },
    {
      accessor: "prepGuidance",
      Header: "Preparation guidance",
      minWidth: 230,
    },
    {
      accessor: "shipQuantity",
      Header: "Quantity",
      minWidth: 70,
    },
    {
      accessor: "prepInstructions",
      Header: "Prep instructions",
      minWidth: 600,
      Cell: ({ row }: { row: Row<ShipmentItemType> }) => {
        const { error, prepInstructions } = row.original;

        return (
          <div className="w-full">
            {!error ? (
              prepInstructions.length > 0 ? (
                prepInstructions.map((instruction, i: number) => (
                  <div
                    key={i}
                    className="flex gap-1.5 justify-between items-start"
                  >
                    <p className="w-[200px] truncate">
                      <span className="font-semibold">Instruction:</span>{" "}
                      {instruction.name}
                    </p>
                    <p className="w-[150px]">
                      <span className="font-semibold">Fees: </span>
                      {instruction?.amazonFee?.amount}{" "}
                      {instruction?.amazonFee?.currencyCode}
                    </p>
                    <select
                      onChange={(e) =>
                        handlePrepOwner(e.target.value, row.index, i)
                      }
                      className="bg-transparent border-0 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[100px] "
                    >
                      <option
                        value="SELLER"
                        selected={instruction.prepOwner === "SELLER"}
                      >
                        Seller
                      </option>
                      <option
                        value="AMAZON"
                        selected={instruction.prepOwner === "AMAZON"}
                      >
                        Amazon
                      </option>
                    </select>
                  </div>
                ))
              ) : (
                <p className="font-semibold">No action required</p>
              )
            ) : (
              <p className="text-red-500">{error}</p>
            )}
          </div>
        );
      },
    },
    {
      Header: "Delete",
      accessor: "delete",
      minWidth: 30,
      Cell: ({ row }: { row: Row<ShipmentItemType> }) => (
        <button
          onClick={() => removeSKu(row.original.sellerSku)}
          className="text-[20px] text-red-600 hover:text-red-800 flex justify-center w-full"
        >
          <MdDelete />
        </button>
      ),
    },
  ];

  return (
    <div className="border-[1px] border-gray-100 rounded shadow">
      <div>
        <div className="overflow-x-auto max-h-[69vh] overflow-y-auto">
          {tempTransfer?.shipmentPrep?.items && (
            <DataTable
              dataTable={tempTransfer.shipmentPrep.items}
              columnsTable={columnsStep2React}
            />
          )}
        </div>
        <div className="flex justify-between items-center gap-3 px-3 bg-white pt-6 pb-5">
          <div>
            {uoms && (
              <div className="relative">
                <label
                  htmlFor="uoms"
                  className="absolute -top-2.5 left-2 px-1 bg-white block text-sm font-medium text-gray-900 "
                >
                  Preferred UOM
                </label>
                <select
                  id="uoms"
                  onChange={(e) => setPreferUomId(Number(e.target.value))}
                  className="bg-white border shadow border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[180px] p-2.5 "
                >
                  <option value={undefined}>Use default</option>
                  {uoms.map((uom) => {
                    return (
                      <option key={uom.id} value={uom.id}>
                        {uom.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div>
          <div className="flex justify-end items-center gap-3 ">
            <div className="relative">
              <label
                htmlFor="preps"
                className="absolute -top-2.5 left-2 px-1 bg-white block text-sm font-medium text-gray-900 "
              >
                Label prep preference
              </label>
              <select
                id="preps"
                onChange={(e) => setLabelPrepPreference(e.target.value)}
                className="bg-white border shadow border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[180px] md:w-[250px] p-2.5 "
              >
                <option value="SELLER_LABEL">SELLER LABEL</option>
                <option value="AMAZON_LABEL_ONLY">AMAZON LABEL ONLY</option>
                <option value="AMAZON_LABEL_PREFERRED">
                  AMAZON LABEL PREFERRED
                </option>
              </select>
            </div>
            <button
              onClick={sendData}
              disabled={
                loading ||
                tempTransfer?.shipmentPrep?.items?.some(
                  (item) => item.error !== null
                )
              }
              className="bg-blue-600 hover:bg-blue-700 shadow-md text-white font-semibold rounded-md disabled:bg-gray-400 w-[190px] h-[48px] sm:h-[40px]"
            >
              {loading ? (
                <div className="flex justify-center">
                  <ReactLoading type="spinningBubbles" height={30} width={30} />
                </div>
              ) : (
                "Confirm and continue"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
