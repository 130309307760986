import { Link } from "react-router-dom";

export const PageNotFound = () => {
  return (
    <div className="h-[100vh] flex justify-center items-center">
      <div className="flex flex-col justify-center items-center p-6 bg-gray-300 shadow-md rounded-md">
        <p className="text-[95px] text-white font-bold">404</p>
        <p className="font-semibold text-lg text-[#4d5969] pb-2">
          The page you're looking for is not found.
        </p>
        <p className="pt-2 border-t-[1px] border-slate-300 w-[300px] text-center">
          Make sure the address is correct and that the page hasn't moved.
        </p>
        <Link
          to={"/"}
          className="bg-blue-600 hover:bg-blue-700 shadow-md p-2 text-white rounded-md cursor-pointer mt-5"
        >
          Go to Home
        </Link>
      </div>
    </div>
  );
};
