import { Column } from "react-table";
import { GridColDef } from "@mui/x-data-grid";
import { ColumnType } from "../type/stepsType";
import { ProgressBar } from "../components/homepage/ProgressBar";
import { InputComponent } from "../components/selectedInventory/InputComponent";
import { DeleteComponent } from "../components/selectedInventory/DeleteComponent";
import { ShipmentSkuType } from "../type/stepsType";
import { FbaInboundDocument } from "../api-client";

export const columnsSearch: GridColDef[] = [
  {
    field: "linkedItemTitle",
    valueGetter: (params) => params.row.linkedItem?.item?.title,
    headerName: "Item details",
    flex: 2,
    minWidth: 300,
    sortable: false,
    disableColumnMenu: true,
    align: "left",
  },
  {
    field: "linkedItemSku",
    valueGetter: (params) => params.row.linkedItem?.item?.sku,
    headerName: "SKU",
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    minWidth: 150,
  },
  {
    field: "mpSku",
    headerName: "Marketplace SKU",
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    minWidth: 150,
  },
  {
    field: "qtyListed",
    headerName: "Quantity Listed",
    flex: 0.5,
    minWidth: 80,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "qtyAvailable",
    headerName: "Available",
    flex: 0.5,
    valueGetter: (params) => params.row.linkedItem?.inventory?.available,
    sortable: false,
    disableColumnMenu: true,
    minWidth: 80,
  },
  {
    field: "qtyOnHand",
    headerName: "Quantity on hand",
    flex: 0.5,
    sortable: false,
    disableColumnMenu: true,
    minWidth: 80,
    valueGetter: (params) => params.row.linkedItem?.inventory?.onHand,
  },
];

export const columnsDetailsReact: ColumnType[] = [
  {
    accessor: "fulfillmentNetworkSKU",
    Header: "Fulfillment SKU",
    minWidth: 500,
  },
  {
    accessor: "sellerSKU",
    Header: "Seller SKU",
    minWidth: 300,
  },
  {
    accessor: "quantity",
    Header: "Quantity",
    Cell: ({ row }: { row: any }) => (
      <div className="flex gap-1">
        <p>{row.original.quantity}</p>
        {row.original.itemUom && (
          <p>
            ({row.original.itemUom.uomQuantity} {row.original.itemUom.uomName})
          </p>
        )}
      </div>
    ),
    minWidth: 80,
  },
];

export const columnsAddProductReact: ColumnType[] = [
  {
    accessor: (row: ShipmentSkuType) => row.linkedItem?.item?.title,
    Header: "Title",
    minWidth: 450,
  },
  {
    Header: "SKU",
    accessor: (row: ShipmentSkuType) => row.linkedItem?.item?.sku,
    minWidth: 200,
  },

  {
    Header: "Marketplace SKU",
    accessor: "mpSku",
    minWidth: 350,
  },
  {
    Header: "Quantity to ship",
    accessor: "shipQuantity",
    minWidth: 150,
    Cell: ({ row }: { row: any }) => (
      <InputComponent
        id={row.original.mpSku as string}
        quantity={row.original.shipQuantity as number}
      />
    ),
  },
  {
    Header: "Available",
    accessor: (row: ShipmentSkuType) => row.linkedItem?.inventory?.available,
    minWidth: 125,
  },
  {
    Header: "Quantity listed",
    accessor: "qtyListed",
    minWidth: 125,
  },

  {
    Header: "Quantity on hand",
    accessor: (row: ShipmentSkuType) => row.linkedItem?.inventory?.onHand,
    minWidth: 140,
  },
  {
    Header: "Delete",
    accessor: "delete",
    minWidth: 60,
    Cell: ({ row }: { row: any }) => (
      <DeleteComponent id={row.original.mpSku} />
    ),
  },
];

export const columnsPackingReact: ColumnType[] = [
  {
    accessor: "itemDetails",
    Header: "Item details",
    width: 300,
  },
  {
    accessor: "sku",
    Header: "SKU",
    width: 150,
  },
  {
    accessor: "condition",
    Header: "Condition",
    width: 150,
  },
  {
    accessor: "shipQuantity",
    Header: "Quantity",
    width: 80,
  },
  {
    accessor: "available",
    Header: "Available",
    width: 80,
  },
];

export const columnsTrnList: Column<FbaInboundDocument>[] = [
  {
    Header: "TRN",
    accessor: "trn",
  },
  {
    Header: "Shipment",
    accessor: "trnReference",
  },
  {
    Header: "Marketplace",
    accessor: (d) => d.marketplace?.name,
  },
  {
    Header: "Date",
    accessor: "date",
  },

  {
    Header: "Packing Status",
    accessor: "packingStatus",
    Cell: ({ row }: { row: any }) => {
      const { itemQtyConfirmed, itemQtyPacked } = row.original;
      const ratio = itemQtyPacked / itemQtyConfirmed;
      return (
        <ProgressBar
          ratio={ratio}
          label={`${itemQtyPacked} out of ${itemQtyConfirmed} packed`}
        />
      );
    },
  },
  {
    Header: "Receive Status",
    accessor: "receiveStatus",
    Cell: ({ row }) => {
      const { itemQtyReceived, itemQtyPacked } = row.original;
      if (!itemQtyReceived || !itemQtyPacked) return null;

      const ratio = itemQtyReceived / itemQtyPacked;

      return (
        <ProgressBar
          ratio={ratio}
          label={`${itemQtyReceived} out of ${itemQtyPacked} received`}
        />
      );
    },
  },
];

export const columnsShipment = [
  {
    Header: "Serial",
    accessor: "serial",
    width: 90,
  },
  {
    Header: "Packing Date",
    accessor: "packingDate",
    Cell: ({ value }: any) => (
      <span>{new Date(value).toLocaleDateString()}</span>
    ),
    width: 90,
  },
  {
    Header: "Weight",
    accessor: "weight",
    Cell: ({ value }: any) => (
      <span>{value ? `${value.value} ${value.uom}` : "N/A"}</span>
    ),
    width: 100,
  },
  {
    Header: "Dimensions",
    accessor: "dimensions",
    Cell: ({ value }: any) => (
      <span>
        {value.width || value.height || value.length
          ? `${value.width ?? "N/A"} x ${value.height ?? "N/A"} x ${
              value.length ?? "N/A"
            } ${value.uom}`
          : "N/A"}
      </span>
    ),
  },
];
