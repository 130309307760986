import { useEffect, useState, FC } from "react";
import { handleError } from "../../utils/functions";
import { FilterInterface, StatusType } from "../../type/stepsType";
import { MarketplacesService, MarketplaceDetails } from "../../api-client";

export const Filter: FC<FilterInterface> = ({
  getTrn,
  marketplaceId,
  setMarketplaceId,
  setPackingStatusSelected,
  setReceiveStatusSelected,
  packingStatusSelected,
  receiveStatusSelected,
}) => {
  const [marketplacesArray, setMarketplacesArray] = useState<
    MarketplaceDetails[]
  >([]);

  const handleMarketplaceSelection = (marketPlaceId: string) => {
    setMarketplaceId(marketPlaceId);
    getTrn(marketPlaceId, undefined, undefined);
  };

  const isStatusType = (value: string): value is StatusType => {
    return ["PENDING", "PARTIALLY", "COMPLETE", "NA"].includes(value);
  };

  const handleFilterSelection = (value: string, type: string) => {
    if (!isStatusType(value)) {

      return;
    }

    if (type === "packing") {
      let tempArray = [...packingStatusSelected];
      if (packingStatusSelected.includes(value)) {
        tempArray = packingStatusSelected.filter((status) => status !== value);
      } else {
        tempArray.push(value);
      }

      setPackingStatusSelected(tempArray);
      getTrn(undefined, tempArray, receiveStatusSelected);
    } else {
      let tempArray = [...receiveStatusSelected];
      if (receiveStatusSelected.includes(value)) {
        tempArray = receiveStatusSelected.filter((status) => status !== value);
      } else {
        tempArray.push(value);
      }
      setReceiveStatusSelected(tempArray);
      getTrn(undefined, packingStatusSelected, tempArray);
    }
  };

  const getMarketPlaces = async () => {
    try {
      const response = await MarketplacesService.getMarketplaces();
      if (response.error) {
        handleError(response.error);
        return;
      }

      if (response.data?.results) {
        setMarketplacesArray(
          response.data.results.filter(
            (marketplace) => marketplace.type === "amazon"
          )
        );
      }
    } catch (error: any) {
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
  };

  useEffect(() => {
    getMarketPlaces();
  }, []);

  return (
    <div className="flex flex-col gap-4 md:flex-row md:gap-0 items-center">
      <div>
        <p className="text-lg font-semibold text-gray-700">Marketplace</p>
        <select
          required
          onChange={(e) => handleMarketplaceSelection(e.target.value)}
          disabled={marketplacesArray.length === 0}
          className="appearance-none bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-[250px] md:w-[150px] lg:w-[250px] px-4 py-1 disabled:bg-gray-100 disabled:cursor-not-allowed shadow hover:shadow-md transition duration-200 ease-in-out"
          value={marketplaceId || ""}
        >
          <option value="">All</option>
          {marketplacesArray.map((marketplace) => (
            <option key={marketplace.id} value={marketplace.id || ""}>
              {marketplace.name}
            </option>
          ))}
        </select>
      </div>
      <div className="border-y md:border-x md:border-y-0 py-4 md:px-4 md:py-0  mx-4 border-gray-300">
        <p className="text-lg font-semibold text-gray-700">Packing status</p>
        <div className="flex mt-1 gap-1">
          <button
            onClick={() => handleFilterSelection("PENDING", "packing")}
            className={`${
              packingStatusSelected.includes("PENDING")
                ? "bg-blue-500 text-white border-blue-700 shadow-md"
                : "bg-white hover:bg-gray-200"
            } transition duration-300 ease-in-out px-2 py-0.5 border border-gray-300 text-sm rounded-lg font-semibold hover:opacity-80`}
          >
            Pending
          </button>
          <button
            onClick={() => handleFilterSelection("PARTIALLY", "packing")}
            className={`${
              packingStatusSelected.includes("PARTIALLY")
                ? "bg-blue-500 text-white border-blue-700 shadow-md"
                : "bg-white hover:bg-gray-200"
            } transition duration-300 ease-in-out px-2 py-0.5 border border-gray-300 text-sm rounded-lg font-semibold hover:opacity-80`}
          >
            Partially
          </button>
          <button
            onClick={() => handleFilterSelection("COMPLETE", "packing")}
            className={`${
              packingStatusSelected.includes("COMPLETE")
                ? "bg-blue-500 text-white border-blue-700 shadow-md"
                : "bg-white hover:bg-gray-200"
            } transition duration-300 ease-in-out px-2 py-0.5 border border-gray-300 text-sm rounded-lg font-semibold hover:opacity-80`}
          >
            Complete
          </button>
        </div>
      </div>
      <div>
        <p className="text-lg font-semibold text-gray-700">Receive status</p>
        <div className="flex mt-1 gap-1">
          <button
            onClick={() => handleFilterSelection("PENDING", "receive")}
            className={`${
              receiveStatusSelected.includes("PENDING")
                ? "bg-blue-500 text-white border-blue-700 shadow-md"
                : "bg-white hover:bg-gray-200"
            } transition duration-300 ease-in-out px-2 py-0.5 border border-gray-300 text-sm rounded-lg font-semibold hover:opacity-80`}
          >
            Pending
          </button>
          <button
            onClick={() => handleFilterSelection("PARTIALLY", "receive")}
            className={`${
              receiveStatusSelected.includes("PARTIALLY")
                ? "bg-blue-500 text-white border-blue-700 shadow-md"
                : "bg-white hover:bg-gray-200"
            } transition duration-300 ease-in-out px-2 py-0.5 border border-gray-300 text-sm rounded-lg font-semibold hover:opacity-80`}
          >
            Partially
          </button>
          <button
            onClick={() => handleFilterSelection("COMPLETE", "receive")}
            className={`${
              receiveStatusSelected.includes("COMPLETE")
                ? "bg-blue-500 text-white border-blue-700 shadow-md"
                : "bg-white hover:bg-gray-200"
            } transition duration-300 ease-in-out px-2 py-0.5 border border-gray-300 text-sm rounded-lg font-semibold hover:opacity-80`}
          >
            Complete
          </button>
        </div>
      </div>
      {/*       <button
        disabled={!isChanged}
        onClick={handleApplyFilter}
        className="bg-blue-500 text-white disabled:opacity-50 px-3 ml-4 h-fit py-2 rounded-sm self-end"
      >
        Apply filter
      </button> */}
    </div>
  );
};
