import { useTable, useFlexLayout } from "react-table";

export const ShipmentTable = ({ data, columns }: any) => {
  const tableInstance = useTable({ columns, data }, useFlexLayout);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div className="max-h-[250px] overflow-x-auto">
      <table
        {...getTableProps()}
        className="min-w-full"
      >
        <thead className="sticky top-0 z-10 ">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  {...column.getHeaderProps()}
                  className={`${
                    columnIndex === headerGroup.headers.length - 1
                      ? "rounded-r-md"
                      : ""
                  } ${
                    columnIndex === 0
                      ? "rounded-l-md"
                      : ""
                  } p-2 text-left text-sm font-medium text-gray-500  bg-gray-100`}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => (
                  <td
                    {...cell.getCellProps()}
                    className={`text-left text-sm p-2 text-gray-500 border-b border-gray-200`}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>{" "}
    </div>
  );
};
