import {
  FbaInboundItemListPrepInstructionsResponse,
  MarketplacesService,
} from "../../api-client";
import ReactLoading from "react-loading";
import { DataTable } from "../DataTable";
import { TableSearch } from "./TableSearch";
import { PasteInbound } from "./PasteInbound";
import { useState, useEffect, FC } from "react";
import { useSearchParams } from "react-router-dom";
import { handleError } from "../../utils/functions";
import { FaPlus, FaRegClipboard } from "react-icons/fa";
import { SelectInventoryProps } from "../../type/stepsType";
import { columnsAddProductReact } from "../../utils/columns";
import { useTempTransferStore, useSkusStore } from "../../store/tempTransfer";

type PrepInstructionType = FbaInboundItemListPrepInstructionsResponse & {
  listingTitle: string | null | undefined;
  shipQuantity: number | undefined;
};

export const SelectInventory: FC<SelectInventoryProps> = ({
  showSelectInventory,
  setStep1Completed,
}) => {
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [showPasteInbound, setShowPasteInbound] = useState<boolean>(false);
  const { skus, setSkus } = useSkusStore();
  const { tempTransfer, setTempTransfer } = useTempTransferStore();

  const [loading, setLoading] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<string | undefined>();

  const [searchParams] = useSearchParams();

  const sendData = async () => {
    const mpId = tempTransfer?.shipmentInfo?.shipDestination.marketPlaceId;

    if (skus?.length < 1 || !mpId) {
      return;
    }
    const payload: any = { items: [], shipToCountryCode: countryCode };
    skus.forEach((element) => {
      payload.items.push({
        sellerSku: element.mpSku,
        quantity: element.shipQuantity,
      });
    });

    try {
      const response =
        await MarketplacesService.postMarketplacesMpidAmazonFbaInboundPrepInstructions(
          {
            body: payload,
            path: {
              mpId,
            },
          }
        );

      const result = response.data?.results;
      if (!result) {
        setLoading(false);
        return false;
      }
      for (const skusPrep of result as PrepInstructionType[]) {
        for (const sku of skus) {
          if (skusPrep.sellerSku === sku.mpSku) {
            skusPrep.shipQuantity = sku.shipQuantity;
            skusPrep.listingTitle = sku.listingTitle;
            if (
              skusPrep.prepInstructions?.length &&
              skusPrep.prepInstructions?.length > 0
            ) {
            
              for (const instruction of skusPrep.prepInstructions)
                  // @ts-ignore
                instruction.prepOwner = "SELLER";
            }
            break;
          }
        }
      }
      return result;
    } catch (error: any) {
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }

      setLoading(false);
      return false;
    }
  };

  const completeStep = async () => {
    setLoading(true);
    const results = await sendData();
    if (!results || !tempTransfer) return;
    const newObject = { ...tempTransfer };

    newObject.shipmentPrep = {
      items: results,
      labelPrepPreference: newObject?.shipmentPrep?.labelPrepPreference,
      shipToCountryCode: countryCode,
    };
    setTempTransfer(newObject);

    setStep1Completed(true);

    setLoading(false);
  };

  useEffect(() => {
    const key = searchParams.get("key");
    if (key && tempTransfer) {
      setSkus(tempTransfer.shipmentPrep?.items || []);
    }
    if (showSelectInventory) {
      setCountryCode(tempTransfer?.shipmentPrep?.shipToCountryCode);
    }
  }, [showSelectInventory]);

  return (
    <div className=" border-gray-300 rounded">
      {showSearch && (
        <TableSearch
          setModalOpen={setShowSearch}
          skus={skus}
          setSkus={setSkus}
        />
      )}
      {showPasteInbound && (
        <PasteInbound
          setModalOpen={setShowPasteInbound}
          skus={skus}
          setSkus={setSkus}
        />
      )}
      <>
        {skus.length > 0 ? (
          <DataTable dataTable={skus} columnsTable={columnsAddProductReact} />
        ) : (
          <div
            className={`${
              !showSelectInventory && "opacity-40"
            }  flex flex-col gap-1 justify-center items-center w-full h-[200px]`}
          >
            <p className="text-[25px]">No items selected</p>
            {skus.length < 1 && (
              <div className="flex gap-1 items-center mt-1">
                <button
                  onClick={() => setShowPasteInbound(true)}
                  disabled={!showSelectInventory}
                  className="p-2 px-3 bg-blue-500 hover:bg-blue-600 disabled:bg-gray-700  text-white font-semibold rounded self-center flex gap-2 items-center w-[160px] shadow-md"
                >
                  <span className="text-[18px]">
                    <FaRegClipboard />
                  </span>
                  Paste Inbound
                </button>
                <p className="text-[16px] font-medium mx-2 my-auto">Or</p>
                <button
                  onClick={() => setShowSearch(true)}
                  disabled={!showSelectInventory}
                  className="p-2 px-3 bg-green-500 hover:bg-green-600 disabled:bg-gray-700 text-white font-semibold rounded self-center flex gap-2 items-center w-[160px] shadow-md"
                >
                  <span className="text-[18px]">
                    <FaPlus />
                  </span>
                  Add Products
                </button>
              </div>
            )}
          </div>
        )}
        <div className="flex justify-between items-center p-3 bg-white flex-col sm:flex-row md:pt-5">
          <p className="h-fit">
            SKUs ready to send: <span className="font-bold">{skus.length}</span>
          </p>
          <div className=" flex gap-4 items-center flex-col sm:flex-row mt-4 md:mt-0">
            <div className="relative">
              <label
                htmlFor="shipFrom"
                className={`absolute -top-2.5 left-1.5 bg-white px-1 block text-sm font-medium text-gray-900 `}
              >
                Country
              </label>
              <select
                onChange={(e) => {
                  if (e.target.value === "Select a country") {
                    setCountryCode(undefined);
                  } else {
                    setCountryCode(e.target.value);
                  }
                }}
                value={countryCode}
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[180px] md:w-[150px] p-2.5 disabled:opacity-60 cursor-pointer shadow"
              >
                <option>Select a country</option>
                <option value="CA">Canada</option>
                <option value="MX">Mexico</option>
                <option value="US">United States</option>
                <option value="DE">Germany</option>
                <option value="ES">Spain</option>
                <option value="FR">France</option>
                <option value="GB">United Kingdom</option>
                <option value="IT">Italy</option>
              </select>
            </div>
            {skus.length > 0 && (
              <div className="flex lg:flex-row flex-col gap-2 lg:gap-4">
                <button
                  onClick={() => setShowPasteInbound(true)}
                  disabled={!showSelectInventory}
                  className="p-2 px-3 bg-blue-500 hover:bg-blue-600 disabled:bg-gray-400 text-white font-semibold rounded-md self-center flex gap-2 items-center w-[160px] shadow-md"
                >
                  <span className="text-[18px]">
                    <FaRegClipboard />
                  </span>
                  Paste Inbound
                </button>
                <button
                  onClick={() => setShowSearch(true)}
                  disabled={!showSelectInventory}
                  className="p-2 px-3 bg-green-500 hover:bg-green-600 disabled:bg-gray-400 text-white font-semibold rounded-md self-center flex gap-2 items-center w-[160px] shadow-md"
                >
                  <span className="text-[18px]">
                    <FaPlus />
                  </span>
                  Add Products
                </button>
              </div>
            )}
            <button
              onClick={completeStep}
              disabled={
                countryCode === undefined ||
                !skus.length ||
                !skus.every(
                  (product: any) =>
                    product.hasOwnProperty("shipQuantity") &&
                    product.shipQuantity > 0
                )
              }
              className="p-2 px-3 bg-blue-600 hover:bg-blue-700 shadow-md text-white font-semibold rounded-md disabled:bg-gray-400 w-[190px] h-[40px]"
            >
              {loading ? (
                <div className="flex justify-center">
                  <ReactLoading type="spinningBubbles" height={30} width={30} />
                </div>
              ) : (
                "Confirm and continue"
              )}
            </button>
          </div>
        </div>
      </>
    </div>
  );
};
