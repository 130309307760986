import { Step3Interface, ShipmentPlanType } from "../../type/stepsType";
import { FC } from "react";
import { useTempTransferStore } from "../../store/tempTransfer";
import { ShipmentDetails } from "./ShipDetails";

export const Step3: FC<Step3Interface> = ({}) => {
  const { tempTransfer } = useTempTransferStore();

  /* 
  useEffect(() => {
    if (step2Completed && tempTransfer?.shipmentPlan?.length === 0) {
      MySwal.fire({
        title: "Transfer completed",
        icon: "success",
        allowOutsideClick: false,
        confirmButtonText: "continue",
      }).then((result) => {
        if (result.isConfirmed) {
          MySwal.close();
          setTempTransfer(null);
          navigate("/");
        }
      });
    }
  }, [tempTransfer]); */

  return (
    <div className="border-t-[1px] border-gray-100">
      <div className="bg-white p-2 border-b-[1px] border-x-[1px] border-gray-100 shadow rounded pb-3">
        <h3 className="text-[18px] font-medium"> Shipping plan details</h3>
        <div className="flex gap-5 sm:gap-14 lg:gap-32 mt-3 flex-col sm:flex-row">
          <div>
            <p className="font-semibold">Ship from</p>
            <div className="flex gap-2 items-center">
              <p>{tempTransfer?.shipmentInfo?.shipFrom.warehouseName}</p>
            </div>
          </div>
          <div>
            <p className="font-semibold">Destination marketplace</p>
            <div className="flex gap-2 items-center">
              <p>
                {tempTransfer?.shipmentInfo?.shipDestination.marketPlaceName}
              </p>
            </div>
          </div>
        </div>
      </div>
      {tempTransfer?.shipmentPlan &&
        tempTransfer.shipmentPlan.map((transfer, index: number) => {
          return (
            <ShipmentDetails key={index} transfer={transfer} index={index} />
          );
        })}
    </div>
  );
};
