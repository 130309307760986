import { useMemo, FC } from "react";
import "react-resizable/css/styles.css";
import {DataTableProps } from "../type/stepsType";
import { useTable, useResizeColumns, useFlexLayout } from "react-table";

export const DataTable: FC<DataTableProps> = ({
  dataTable,
  columnsTable,
}) => {
  const data = useMemo(() => dataTable, [dataTable]);
  const columns = useMemo(() => columnsTable, [columnsTable]);
  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: 60,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: data || [],
        defaultColumn,
      },
      useFlexLayout,
      useResizeColumns
    );

  return (
    <div className="w-full ">
      <div className="shadow-lg border overflow-y-auto">
        <table
          {...getTableProps()}
          className="min-w-full divide-y divide-gray-200 table-fixed"
        >
          <thead className="bg-gray-100 ">
            {headerGroups.map((headerGroup, groupIndex) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      ...column.getHeaderProps().style,
                      minWidth: column.minWidth,
                    }}
                    className={`${
                      columnIndex !== headerGroup.headers.length - 1 &&
                      "border-r"
                    } text-center flex items-center justify-center p-1  font-medium text-gray-500  border-gray-300 `}
                  >
                    {column.render("Header")}

                    <div
                      {...(column as any).getResizerProps()}
                      className="absolute right-0 top-0 bottom-0 z-20 cursor-col-resize w-[1px] hover:bg-black hover:w-[2px]"
                    />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 overflow-y-auto ">
            {rows.map((row, rowIndex) => {
              prepareRow(row);
              const rowClassName =
                rowIndex % 2 !== 0 ? "bg-blue-50" : "bg-white";
              return (
                <tr {...row.getRowProps()} className={rowClassName}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={cellIndex}
                        className={`p-1.5 truncate text-sm border-gray-300 ${
                          cellIndex !== row.cells.length - 1 && "border-r"
                        } ${rowIndex % 2 === 0 ? "bg-white" : "bg-blue-50"}`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
