import {
  MarketplacesService,
  MarketplaceDetails,
  InventoryService,
  WarehouseDetails,
} from "../api-client";
import {
  ShipDestinationType,
  ShipFromType,
  Step1Interface,
} from "../type/stepsType";
import { FC, useState, useEffect } from "react";
import { handleError } from "../utils/functions";
import { SelectInventory } from "./selectedInventory/SelectInventory";
import { useTempTransferStore, useSkusStore } from "../store/tempTransfer";

export const Step1: FC<Step1Interface> = ({
  setStep1Completed,
  showSelectInventory,
  setShowSelectInventory,
  resetShipment,
}) => {
  const { setTempTransfer } = useTempTransferStore();
  const { setSkus } = useSkusStore();

  const [warehouses, setWarehouses] = useState<
    WarehouseDetails[] | null | undefined
  >([]);
  const [marketplaces, setMarketplaces] = useState<MarketplaceDetails[]>([]);

  const [shipFrom, setShipFrom] = useState<ShipFromType | undefined>(undefined);
  const [shipDestination, setShipDestination] = useState<
    ShipDestinationType | undefined
  >(undefined);

  const getWarehouse = async () => {
    try {
      const response = await InventoryService.getInventoryWarehouses();

      if (response.error) {
        const { error, errors } = response.error;
        if (error || errors) {
          handleError(response.error);
          return;
        }
      }

      setWarehouses(response.data?.results);
    } catch (error: any) {
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
  };

  const getMarketPlaces = async () => {
    try {
      const response = await MarketplacesService.getMarketplaces();
      if (response.error) {
        handleError(response.error);
        return;
      }

      if (response.data?.results) {
        setMarketplaces(
          response.data?.results?.filter(
            (marketplace) => marketplace.type === "amazon"
          )
        );
      } else {
        setMarketplaces([]);
      }
    } catch (error: any) {
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
  };

  useEffect(() => {
    getWarehouse();
    getMarketPlaces();
  }, []);

  useEffect(() => {
    setShipFrom(undefined);
    setShipDestination(undefined);
  }, [resetShipment]);

  const completeStep1 = (shipDestination: any) => {
    if (!shipFrom) return;

    setTempTransfer({
      shipmentPrep: {
        labelPrepPreference: undefined,
        items: undefined,
        shipToCountryCode: shipDestination.marketPlaceCountry,
      },
      shipmentPlan: null,
      shipmentInfo: {
        shipFrom,
        shipDestination,
      },
    });

    setShowSelectInventory(true);
  };

  const handleAddShipment = (type: string, value: string) => {
    if (!warehouses) return;
    setSkus([]);
    setTempTransfer(null);
    if (value === "") {
      if (type === "from") {
        setShipFrom(undefined);
      } else {
        setShipDestination(undefined);
      }
      return;
    }

    if (type === "from") {
      const warehouseSelected = warehouses.filter(
        (warehouse) => warehouse.id === value
      );
      setShipFrom({
        warehouseId: warehouseSelected[0].id,
        warehouseName: warehouseSelected[0].name,
      });
      setShipDestination(undefined);
    } else {
      const marketplaceSelected = marketplaces.filter(
        (marketplace) => marketplace.id === value
      );
      setShipDestination({
        marketPlaceId: marketplaceSelected[0].id,
        marketPlaceName: marketplaceSelected[0].name,
        marketPlaceCountry:
          marketplaceSelected[0].marketplaceFulfilmentWarehouse?.country,
      });
      completeStep1({
        marketPlaceId: marketplaceSelected[0].id,
        marketPlaceName: marketplaceSelected[0].name,
        marketPlaceCountry:
          marketplaceSelected[0].marketplaceFulfilmentWarehouse?.country,
      });
    }
  };

  return (
    <div className="border-[1px] border-gray-100 rounded shadow">
      <div className="bg-white p-2 flex gap-10 items-center justify-between"></div>
      <div onSubmit={completeStep1} className="bg-white pb-5">
        <div className="flex flex-col lg:flex-row justify-center items-center gap-10 ld:gap-10 pt-5 mb-2">
          <div className="relative">
            <label
              htmlFor="shipFrom"
              className={`${
                (warehouses?.length === 0 || showSelectInventory) &&
                "opacity-40"
              } absolute -top-2.5 left-1.5 bg-white px-1 block text-sm font-medium text-gray-900 z-10`}
            >
              Warehouse to ship from
            </label>
            <select
              id="shipFrom"
              required
              disabled={warehouses?.length === 0 || showSelectInventory}
              value={shipFrom?.warehouseId || ""}
              onChange={(e) => handleAddShipment("from", e.target.value)}
              className="bg-white border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[250px] p-2.5 disabled:opacity-40"
            >
              <option defaultValue="" value="">
                Choose a warehouse
              </option>
              {warehouses?.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </option>
              ))}
            </select>
          </div>
          <div className="relative">
            <label
              htmlFor="shipDestination"
              className={`${
                (marketplaces.length === 0 || showSelectInventory) &&
                "opacity-40"
              } absolute -top-2.5 left-1.5 bg-white px-1 block text-sm font-medium text-gray-900 z-10`}
            >
              Destination marketplace
            </label>
            <select
              required
              id="shipDestination"
              disabled={marketplaces.length === 0 || showSelectInventory}
              value={shipDestination?.marketPlaceId || ""}
              onChange={(e) => handleAddShipment("to", e.target.value)}
              className="bg-white border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[250px] p-2.5 disabled:opacity-40"
            >
              <option defaultValue="" value="">
                Choose a marketplace
              </option>
              {marketplaces.map((marketplace) => (
                <option
                  disabled={
                    shipFrom?.warehouseId ===
                    marketplace?.marketplaceFulfilmentWarehouse?.id
                  }
                  key={marketplace.id}
                  value={marketplace.id || ""}
                >
                  {marketplace.name}
                </option>
              ))}
            </select>
          </div>
          {/*      <div className="flex justify-end bg-white w-[72px]">
            {showSelectInventory ? (
              <button
                type="submit"
                onClick={handleEdit}
                disabled={
                  shipFrom === undefined || shipDestination === undefined
                }
                className="px-4 py-1.5 bg-[#008297] hover:bg-[#006f7c] text-lg text-white font-semibold rounded disabled:bg-gray-400 w-[72px] shadow-md"
              >
                Edit
              </button>
            ) : (
              <div className="  w-[72px] shadow-md"></div>
            )}
          </div> */}
        </div>
      </div>
      <SelectInventory
        showSelectInventory={showSelectInventory}
        setStep1Completed={setStep1Completed}
      />
    </div>
  );
};
