import{ create} from "zustand";
import { TransferType, ShipmentSkuType } from "../type/stepsType";

export interface TempTransferInterface {
  tempTransfer: TransferType | null;
  setTempTransfer: (value: TransferType | null) => void;
}

export const useTempTransferStore = create<TempTransferInterface>((set) => ({
  tempTransfer: null,
  setTempTransfer: (value: TransferType | null) => set({ tempTransfer: value }),
}));

export interface SkusInterface {
  skus: ShipmentSkuType[];
  setSkus: (value: ShipmentSkuType[]) => void;
}

export const useSkusStore = create<SkusInterface>((set) => ({
  skus: [],
  setSkus: (value: ShipmentSkuType[]) => set({ skus: value }),
}));
