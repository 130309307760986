import { FC } from "react";
import { useTable } from "react-table";
import { useNavigate } from "react-router-dom";
import { columnsTrnList } from "../../utils/columns";
import { FbaInboundDocument, User } from "../../api-client";

interface TrnTableProps {
  data: FbaInboundDocument[];
  userInfo: User | undefined;
}

export const TrnTable: FC<TrnTableProps> = ({ data, userInfo }) => {
  const navigate = useNavigate();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: columnsTrnList, data });

  return (
    <table
      {...getTableProps()}
      className="min-w-full max-w-full divide-gray-200 rounded"
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, columnIndex) => (
              <th
                {...column.getHeaderProps()}
                className={`text-left p-1 text-md font-medium text-gray-500 !sticky left-0 z-20 bg-gray-100 ${
                  columnsTrnList.length - 1 === columnIndex
                    ? "rounded-r-md"
                    : ""
                } ${0 === columnIndex ? "rounded-l-md" : ""}`}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          const trnId = row.original.trnId;
          const marketplaceId = row.original.marketplace?.id;
          return (
            <tr
              {...row.getRowProps()}
              onClick={() => navigate(`/mp/${marketplaceId}/trn/${trnId}`)}
              style={{
                cursor: "pointer",
                transition: "transform 200ms ease",
              }}
              className={`scale-[0.999] hover:scale-[1] hover:bg-gray-100 ${
                rowIndex % 2 === 0 ? "bg-white" : "bg-blue-50"
              }`}
            >
              {row.cells.map((cell, cellIndex) => {
                const columnType = columnsTrnList[cellIndex].accessor;
                const value = cell.value;
                return (
                  <td
                    {...cell.getCellProps()}
                    key={cellIndex}
                    className={`p-1.5 truncate text-sm  ${
                      value === "COMPLETE" && "text-green-600"
                    } ${value === "PARTIALLY" && "text-orange-600"} ${
                      value === "PENDING" && "text-yellow-600"
                    } `}
                  >
                    {columnType === "date" && value ? (
                      new Intl.DateTimeFormat("en-uk").format(new Date(value))
                    ) : columnType === "trn" && value ? (
                      <a
                        className="text-blue-600"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${userInfo?.organization?.appUrl}/wapp/en-gb/inventory/trn/${trnId}`}
                        onClick={(event) => event.stopPropagation()}
                      >
                        {cell.render("Cell")}
                      </a>
                    ) : (
                      cell.render("Cell")
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
