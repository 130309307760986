import axios from "axios";
import Swal from "sweetalert2";
import { Step1 } from "./Step1";
import { Step2 } from "./step2/Step2";
import { Step3 } from "./step3/Step3";
import { MdDelete } from "react-icons/md";
import { useEffect, useState, FC } from "react";
import { NewTransferInterface } from "../type/stepsType";
import { MdArrowBackIos } from "react-icons/md";
import { handleError, handleSuccess, swalStyle } from "../utils/functions";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useTempTransferStore, useSkusStore } from "../store/tempTransfer";
import { DocumentsService } from "../api-client";

export const NewTransfer: FC<NewTransferInterface> = ({
  fbaTransfer,
  setFbaTransfer,
  userInfo,
}) => {
  const [step1Completed, setStep1Completed] = useState<boolean>(false);
  const [step2Completed, setStep2Completed] = useState<boolean>(false);
  const [hideBar, setHideBar] = useState<boolean>(false);
  const navigate = useNavigate();
  const [resetShipment, setResetShipment] = useState<boolean>(false);

  const [showSelectInventory, setShowSelectInventory] =
    useState<boolean>(false);

  const { setTempTransfer } = useTempTransferStore();
  const { setSkus } = useSkusStore();

  const goBackToStep = (step: Number) => {
    switch (step) {
      case 1: {
        if (step1Completed) {
          setStep1Completed(false);
          setStep2Completed(false);
        }
        break;
      }
      case 2: {
        if (step2Completed) {
          setStep2Completed(false);
        }
        break;
      }
    }
  };

  const deleteTransfer = () => {
    setTempTransfer(null);
    setSkus([]);
    setShowSelectInventory(false);
    setStep1Completed(false);
    setStep2Completed(false);
    setResetShipment(!resetShipment);
  };

  const handleDeleteTransfer = () => {
    Swal.fire({
      title: "Are you sure you want to reset this transfer?",
      showCancelButton: true,
      position: "top",
      cancelButtonText: "Confirm",
      confirmButtonText: "Cancel",
      allowEnterKey: false,
      ...swalStyle,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close();
      } else if (result.isDismissed) {
        deleteTransfer();
      }
    });
  };

  const [searchParams] = useSearchParams();

  const retrieveObject = async (key: string) => {
    try {
      const response = await DocumentsService.getDocumentsStorageKey({
        path: { key },
      });

      setShowSelectInventory(true);
      // @ts-ignore
      setTempTransfer(response.data);
    } catch (error: any) {
      handleError(error.message);
    }
  };

  useEffect(() => {
    const hideBarParam = searchParams.get("hideBar") === "true";
    setHideBar(hideBarParam);
    const key = searchParams.get("key");

    if (key && userInfo) {
      retrieveObject(key);
    }
  }, [userInfo]);

  return (
    <div
      className={`${
        hideBar ? "min-h-[100vh]" : "min-h-[90vh]"
      } px-1 sm:px-8 pt-3 flex flex-col gap-4 bg-[#f9f9f9] font-sans`}
    >
      <nav
        className="flex flex-col sm:flex-row gap-5 items-center px-5 py-3 border border-gray-100 rounded bg-white shadow"
        aria-label="Breadcrumb"
      >
        <ol className="inline-flex items-center space-x-1 md:space-x-3 w-full font-bold">
          <button
            onClick={() => {
              deleteTransfer();
              navigate(`/`);
            }}
          >
            <MdArrowBackIos size={30} />
          </button>
          <li className="inline-flex items-center text-gray-900">
            <span
              className={step1Completed ? "cursor-pointer" : ""}
              onClick={() => goBackToStep(1)}
            >
              Shipping plan details
            </span>
          </li>
          <li aria-current="page">
            <div className="flex items-center">
              <svg
                className="w-3 h-3 mx-1 text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <span
                onClick={() => goBackToStep(2)}
                className={`${step2Completed ? "cursor-pointer" : ""} ${
                  step1Completed ? "text-gray-900" : "text-gray-400"
                } ml-1 md:ml-2`}
              >
                Prepare and label products
              </span>
            </div>
          </li>
          <li aria-current="page">
            <div className="flex items-center">
              <svg
                className="w-3 h-3 mx-1 text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <span
                onClick={() => goBackToStep(2)}
                className={`ml-1 md:ml-2 ${
                  step2Completed
                    ? "cursor-pointer text-gray-700"
                    : "text-gray-400"
                }`}
              >
                Prepare shipment
              </span>
            </div>
          </li>
        </ol>
        <div>
          <button
            onClick={handleDeleteTransfer}
            className="flex w-[140px] items-center gap-1.5 bg-red-600 hover:bg-red-700 text-white p-1.5 self-end justify-self-end rounded shadow-md"
          >
            Reset transfer
            <span className="text-[19px]">
              <MdDelete />
            </span>
          </button>
        </div>
      </nav>
      <div>
        {!step1Completed && userInfo && (
          <Step1
            resetShipment={resetShipment}
            setStep1Completed={setStep1Completed}
            showSelectInventory={showSelectInventory}
            setShowSelectInventory={setShowSelectInventory}
          />
        )}
        {step1Completed && !step2Completed && (
          <Step2 setStep2Completed={setStep2Completed} />
        )}

        {step2Completed && (
          <Step3
            step2Completed={step2Completed}
            fbaTransfer={fbaTransfer}
            setFbaTransfer={setFbaTransfer}
          />
        )}
      </div>
    </div>
  );
};
