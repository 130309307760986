import axios from "axios";
import Tooltip from "./Tooltip";
import { DataTable } from "../DataTable";
import { useState, FC } from "react";
import { IoIosArrowDown } from "react-icons/io";
import ReactLoading from "react-loading";
import { BsEyeFill } from "react-icons/bs";
import { useTempTransferStore } from "../../store/tempTransfer";
import {
  ShipmentPlanType,
  ShipmentDetailsInterface,
  CreatePlan,
} from "../../type/stepsType";
import { columnsDetailsReact } from "../../utils/columns";
import { GiConfirmed } from "react-icons/gi";
import { handleError } from "../../utils/functions";
import {
  FbaInboundCreateShipmentRequest,
  MarketplacesService,
} from "../../api-client";

export const ShipmentDetails: FC<ShipmentDetailsInterface> = ({
  transfer,
  index,
}) => {
  const [showItems, setShowItems] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);

  const { tempTransfer, setTempTransfer } = useTempTransferStore();

  const createShipment = async (transfer: CreatePlan) => {
    setLoading(true);
    if (
      !tempTransfer?.shipmentInfo ||
      !tempTransfer?.shipmentPrep ||
      !tempTransfer?.shipmentPlan
    )
      return;
    const { marketPlaceId } = tempTransfer.shipmentInfo.shipDestination;
    if (!marketPlaceId) return;
    try {
      const payload = {
        shipmentId: transfer.shipmentId,
        shipmentName: null,
        shipFromWarehouseId: tempTransfer.shipmentInfo.shipFrom.warehouseId,
        fulfillmentCenterId: transfer.destinationFulfillmentCenterId,
        shipToAddress: transfer.shipToAddress,
        labelPrepPreference: tempTransfer.shipmentPrep.labelPrepPreference,
        items: transfer.items?.map((item) => ({
          sellerSKU: item.sellerSKU,
          quantity: item.quantity,
          fulfillmentNetworkSKU: item.fulfillmentNetworkSKU,
          preps: item.prepDetailsList,
        })),
      };
      const response =
        await MarketplacesService.postMarketplacesMpidAmazonFbaInboundCreateShipment(
          {
            path: { mpId: marketPlaceId },
            body: payload as unknown as FbaInboundCreateShipmentRequest,
          }
        );

      if (response.error) {
        const { error, errors} = response.error;
        if (error || errors) {
          handleError(response.error);
          return;
        }
      }

      const result = response.data?.data;
      const trnId = result?.trnId;
      const trnUrl = result?.trnUrl;

      const newTransfer = { ...tempTransfer };

      const planIndex = tempTransfer?.shipmentPlan.findIndex(
        (item) => item.shipmentId === transfer.shipmentId
      );
      if (newTransfer.shipmentPlan) {
        newTransfer.shipmentPlan[planIndex].trnId = trnId!;
        newTransfer.shipmentPlan[planIndex].trnUrl = trnUrl!;
        newTransfer.shipmentPlan[planIndex].completed = true;
      }
      setTempTransfer(newTransfer);
      setLoading(false);
    } catch (error: any) {
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
      setLoading(false);
    }
  };

  const voidShipment = (shipmentId: string) => {
    if (!tempTransfer?.shipmentPlan) return;
    const updatedShipmentPlan = tempTransfer?.shipmentPlan.filter(
      (item) => item.shipmentId !== shipmentId
    );

    const updatedShipmentData = {
      ...tempTransfer,
      shipmentPlan: updatedShipmentPlan,
    };
    setTempTransfer(updatedShipmentData);
  };

  return (
    <div className="bg-white p-2 mt-2 border-[1px] border-gray-100 shadow rounded">
      <div className="flex justify-between mt-2 font-semibold mr-2">
        <p>Shipment {index + 1} details</p>
        <button
          onClick={() => setShowItems(!showItems)}
          className={`${
            showItems ? "rotate-180" : "rotate-0"
          } transition-all duration-200 dark:text-[#7e8896]`}
        >
          <IoIosArrowDown size={18} />
        </button>
      </div>
      <div className="flex items-center justify-between flex-col xl:flex-row m-3 gap-4 xl:gap-0">
        <div className="flex items-center mt-3 flex-col md:flex-row gap-4 md:gap-6 lg:gap-20">
          <div>
            <p className="font-semibold min-w-[140px] text-center md:text-left">
              Shipment ID:
            </p>
            <p> {transfer.shipmentId}</p>
          </div>
          <div>
            <p className="font-semibold text-center md:text-left"> Ship to:</p>
            <div className="flex gap-4">
              <p>
                {transfer.destinationFulfillmentCenterId},{" "}
                {transfer.shipToAddress?.city},{" "}
                {transfer.shipToAddress?.countryCode}
              </p>
              <Tooltip content={transfer.shipToAddress}>
                <BsEyeFill color="#008297" size={22} />
              </Tooltip>
            </div>
          </div>
          <div>
            <p className="font-semibold min-w-[125px] text-center md:text-left">
              Products
            </p>
            <p>
              {transfer.items?.length}
              {transfer.estimatedBoxContentsFee?.totalUnits &&
                `(${transfer.estimatedBoxContentsFee?.totalUnits} total qty)`}
            </p>
          </div>
        </div>
        {!transfer.completed ? (
          <div className="flex gap-2">
            <button
              onClick={() => voidShipment(transfer.shipmentId!)}
              className="p-2 md:p-3 border-[1px] border-gray-400 hover:bg-gray-100 font-semibold rounded shadow-md"
            >
              Void Shipment
            </button>
            <button
              onClick={() => createShipment(transfer)}
              disabled={loading}
              className="p-3 border-[1px] border-[#008297] hover:bg-[#006f7c] font-semibold text-[#006f7c] hover:text-white rounded shadow-md w-[80px] h-[50px]"
            >
              {loading ? (
                <div className="flex justify-center">
                  <ReactLoading type="spinningBubbles" height={30} width={30} />
                </div>
              ) : (
                "Accept"
              )}
            </button>
          </div>
        ) : (
          <div className="flex gap-4 items-center">
            <p className="bg-green-500 text-white rounded-full px-2 py-1 flex items-center gap-2">
              Processed <GiConfirmed />
            </p>
            <p className="flex items-center gap-1 text-lg">
              Trn:
              <span className="font-medium">
                <a
                  className="text-blue-400"
                  rel="noreferrer"
                  href={transfer.trnUrl}
                  target="_blank"
                >
                  {transfer.trnId}
                </a>
              </span>
            </p>
          </div>
        )}
      </div>
      {showItems && (
        <div className="overflow-y-auto max-h-[500px] mt-16 sm:mt-4 bg-[#f9f9f9]">
          <DataTable
            dataTable={transfer.items}
            columnsTable={columnsDetailsReact}
          />
        </div>
      )}
    </div>
  );
};
