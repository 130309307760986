import Tooltip from "@mui/material/Tooltip";
import { FC } from "react";

interface ProgressBarProps {
  ratio: number;
  label: string;
}
export const ProgressBar: FC<ProgressBarProps> = ({ ratio, label }) => {

  const barWidth = `${ratio * 100}%`;
  const barColor = calculateBarColor(ratio);

  return (
    <Tooltip arrow placement="top" PopperProps={tooltipStyles} title={label}>
      <div className="max-w-[150px] border bg-gray-200 rounded relative shadow-md">
        <div
          style={{ width: barWidth }}
          className={`h-4 ${barColor} rounded shadow-md`}
        >
          <p
            className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center m-0 text-slate-700 semibold"
            style={{ zIndex: 1 }}
          >
            {(ratio * 100).toFixed(0)}%
          </p>
        </div>
      </div>
    </Tooltip>
  );
};

const tooltipStyles = {
  sx: {
    "& .MuiTooltip-tooltip": {
      fontSize: "0.8rem",
      fontWeight: "semibold",
    },
  },
};

const calculateBarColor = (ratio: number) => {
  if (ratio < 0.33) return "bg-red-500";
  if (ratio < 0.66) return "bg-orange-500";
  if (ratio < 1) return "bg-yellow-400";
  return "bg-green-500";
};
