import axios from "axios";
import { User } from "../api-client";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import { FC, useEffect, useState } from "react";

interface UserComponentInterface {
  userInfo: User | undefined;
}

export const TopBar: FC<UserComponentInterface> = ({ userInfo }) => {
  const [hideBar, setHideBar] = useState<boolean>(false);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const hideBarParam = queryParams.get("hideBar") === "true";
    setHideBar(hideBarParam);
  }, []);

  return (
    <div>
      {!hideBar && (
        <div className="p-3 flex flex-col md:flex-row h-[90px] px-8 justify-between items-center bg-white border-b-2">
          <Link
            to={`/?${axios.defaults.headers.common["Authorization"]}`}
            className="flex gap-4 items-center"
          >
            <img src="/logo-tradepeg.png" alt="logo" className="h-14" />
            <h1 className="font-semibold text-[30px] sm:h-[35px]">
              FBA Inbound
            </h1>
          </Link>
          {userInfo && (
            <div className="hidden sm:flex gap-3 items-center">
              <h2 className="font-medium text-[20px]">{userInfo.name}</h2>{" "}
              {userInfo.organization?.logo ? (
                <img
                  src={userInfo.organization.logo}
                  alt="logo"
                  className="h-10"
                />
              ) : (
                <div className="h-10 w-10"></div>
              )}
            </div>
          )}
        </div>
      )}
      <Outlet />
    </div>
  );
};
