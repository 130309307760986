import {
  FbaInboundPutTransportDetailsRequest,
  FbaInboundPutTransportDetailsResponse,
  MarketplacesService,
} from "../../api-client";
import { FC, useState, ChangeEvent } from "react";
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { PackageActionInterface } from "../../type/stepsType";
import { handleError, handleSuccess } from "../../utils/functions";


export const SendPackages: FC<PackageActionInterface> = ({
  showModal,
  setShowModal,
  packages,
  setPackages,
  isNonPartnered,
  setIsNonPartnered,
}) => {
  let params = useParams();
  const trnId = params.trnId;

  const [isLtl, setIsLtl] = useState<boolean>(false);
  const [transport, setTransport] = useState<FbaInboundPutTransportDetailsResponse | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCarrier, setSelectedCarrier] = useState<string>();
  const [carrierError, setCarrierError] = useState<boolean>(false);

  const handleCloseModal = () => {
    setPackages(undefined);
    setShowModal(false);
    setTransport(undefined);
    setLoading(false);
    setIsNonPartnered(false);
  };

  const allCarrierOptions = [
    { id: "DHL_AIRWAYS_INC", name: "DHL" },
    { id: "DHL_UK", name: "DHL UK" },
    { id: "PARCELFORCE", name: "Parcel Force" },
    { id: "DPD", name: "DPD" },
    { id: "TNT_LOGISTICS_CORPORATION", name: "TNT Logistics Corporation" },
    { id: "TNT", name: "TNT" },
    { id: "YODEL", name: "Yodel" },
    { id: "UNITED_PARCEL_SERVICE_INC", name: "United Parcel Service Inc" },
    { id: "OTHER", name: "Other" },
    { id: "DHL_EXPRESS_USA_INC", name: "DHL Express USA" },
    { id: "FEDERAL_EXPRESS_CORP", name: "Federal Express Corp" },
    {
      id: "UNITED_STATES_POSTAL_SERVICE",
      name: "United States Postal Service",
    },
    { id: "UNITED_PARCEL_SERVICE_INC", name: "United Parcel Service Inc" },
    /*   { id: "OTHER", name: "Other" }, */
  ];

  const handleCarrierSelection = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedCarrier(e.target.value);
    setCarrierError(false);
  };

  const handleSendDetails = async () => {
    if (isNonPartnered && !selectedCarrier) {
      setCarrierError(true);
    }

    setLoading(true);
    try {
      if (!packages) {
        throw new Error("Packages not found.");
      }
      const body: FbaInboundPutTransportDetailsRequest = {
        isLtl,
        packages: packages as number[] | undefined,
        carrierName: isNonPartnered ? selectedCarrier : null,
      };
      const response =
        await MarketplacesService.postMarketplacesAmazonFbaInboundTrnidTransport(
          {
            body: body,
            path: { trnId: Number(trnId) },
          }
        );
  
        if (response.error) {
          const { error, errors } = response.error;
          if (error || errors) {
            handleError(response.error);
            return;
          }
        }
      setTransport(response.data?.data);
    } catch (error: any) {
      handleError(error.message);

      return;
    }
    setLoading(false);
  };

  const handleConfirmTransport = async () => {
    setLoading(true);
    try {
      if (!packages) {
        throw new Error("Packages not found.");
      }
      const body: FbaInboundPutTransportDetailsRequest = {
        isLtl,
        packages: packages as number[] | undefined,
        carrierName: isNonPartnered ? selectedCarrier : null,
      };
      const response =
        await MarketplacesService.postMarketplacesAmazonFbaInboundTrnidTransportConfirm(
          { body: body, path: { trnId: Number(trnId) } }
        );
      if (response.error) {
        const { error, errors } = response.error;
        if (error || errors) {
          handleError(response.error);
          return;
        }
      }
    } catch (error: any) {
      handleError(error.message);

      return;
    }
    handleCloseModal();
    handleSuccess("Transport confirmed successfully", true);
    return;
  };

  return (
    <div>
      <div
        className={`fixed inset-0 w-full h-full bg-black bg-opacity-30 ${
          !showModal ? "-z-10" : "z-[100]"
        }`}
      >
        <div className="p-4 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[560px] bg-white rounded-md">
          <div className="relative">
            <button
              className="absolute -right-8 -top-8 bg-white rounded-full"
              onClick={handleCloseModal}
            >
              <AiFillCloseCircle color="red" size={40} />
            </button>
          </div>
          {packages && (
            <div className="flex flex-col justify-between">
              <div>
                <p className="text-2xl font-semibold">
                  {isNonPartnered ? "Notify Receiver" : "Send Packages"}
                </p>
              </div>
              {/*   <div>
                <p className="text-xl my-2 font-semibold">Packages to send</p>
                <ShipmentTable data={packages} columns={columnsShipment} />
              </div> */}
              {!loading ? (
                transport ? (
                  <div>
                    <p className="text-xl font-semibold mb-4">Transport Info</p>
                    <div className="h-[105px]">
                      <div>
                        <p className="text-xl font-semibold">Carrier:</p>
                        <p className="text-gray-800">
                          {transport?.carrierName}
                        </p>
                      </div>
                      <div>
                        <p className="text-xl font-semibold">Cost:</p>
                        <p className="text-gray-800">
                          {transport?.cost?.currencyCode}
                          {transport?.cost?.amount}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-center w-full mt-5">
                      <button
                        onClick={handleConfirmTransport}
                        className="border-[1px] bg-blue-500 text-white rounded-md font-semibold p-3 h-fit w-[170px]"
                      >
                        Confirm Transport
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="mt-5">
                    {isNonPartnered && (
                      <div className="mb-4">
                        <p className="text-lg font-semibold mb-2">
                          Select Carrier:
                        </p>
                        <select
                          id="carrierSelect"
                          value={selectedCarrier}
                          onChange={handleCarrierSelection}
                          className={`${
                            carrierError ? "border-red-500" : "border-gray-400"
                          } border p-2 rounded-md border-gray-400 focus:outline-none focus:border-blue-500 text-sm`}
                        >
                          <option value={undefined} disabled>
                            Choose a Carrier
                          </option>
                          {allCarrierOptions.map((carrier) => (
                            <option key={carrier.id} value={carrier.id}>
                              {carrier.name}
                            </option>
                          ))}
                        </select>
                        {carrierError && (
                          <p className="text-red-500 text-sm pt-0.5">
                            Select a carrier
                          </p>
                        )}
                      </div>
                    )}
                    <div>
                      <h2 className="text-xl font-semibold mb-4">
                        Shipping mode
                      </h2>
                      <div className="grid grid-cols-2 gap-4 h-[80px]">
                        <div className="flex items-center p-3 bg-gray-50 rounded border shadow-md">
                          <input
                            type="radio"
                            name="shippingMode"
                            id="spd"
                            className="mr-2"
                            checked={!isLtl}
                            onChange={() => setIsLtl(false)}
                          />
                          <label htmlFor="spd" className="flex flex-col">
                            <span>Small parcel delivery (SPD)</span>
                          </label>
                        </div>

                        <div className="flex items-center p-3 bg-gray-50 rounded border shadow-md">
                          <input
                            type="radio"
                            name="shippingMode"
                            id="ltl"
                            className="mr-2"
                            checked={isLtl}
                            onChange={() => setIsLtl(true)}
                          />
                          <label htmlFor="ltl">Less than truckload (LTL)</label>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center w-full mt-5">
                      <button
                        onClick={handleSendDetails}
                        className="border-[1px] bg-blue-500 text-white rounded-md font-semibold p-2 h-fit px-3 text-lg"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )
              ) : (
                <div className="flex w-full justify-center items-center flex-col gap-5 h-[220px]">
                  <ReactLoading
                    type="spinningBubbles"
                    height="150px"
                    width="150px"
                    color="#03c160"
                  />
                  <p className="text-[15px] text-center">
                    Loading typically completes in under 10 seconds, depending
                    on your internet connection it may take longer
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
