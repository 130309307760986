import { MdDelete } from "react-icons/md";
import { useSkusStore } from "../../store/tempTransfer";

export const DeleteComponent = ({ id }: { id: string }) => {
  const { skus, setSkus } = useSkusStore();

  const removeSKu = () => {
    const newArray = [...skus];
    const filteredArray = newArray.filter((product) => product.mpSku !== id);

    setSkus(filteredArray);
  };

  return (
    <button
      onClick={removeSKu}
      className="text-[20px] text-red-500 hover:text-red-600 flex justify-center w-full"
    >
      <MdDelete />
    </button>
  );
};
