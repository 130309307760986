import { useState } from "react";

const Tooltip = ({ content, children }: any) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      className="relative inline-block group"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}{" "}
      {showTooltip && (
        <div className="flex flex-col gap-1.5  opacity-0 group-hover:opacity-100 absolute z-10 w-[400px] bg-white text-gray-800 border-[1px] border-gray-800  py-2 px-4 rounded-lg bottom-full left-1/2 transform -translate-x-1/2 transition-opacity duration-300">
          <p className="font-semibold">{content.name}</p>
          <p className="font-semibold">{content.addressLine1}</p>
          <p className="font-semibold">{content.addressLine2}</p>
          <p className="font-semibold">{content.city}</p>
          <p className="font-semibold">{content.postalCode}</p>
          <p className="font-semibold"> {content.countryCode}</p>
        </div>
      )}
    </div>
  );
};

export default Tooltip;