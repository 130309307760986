import {
  FbaInboundDocumentWithShipment,
  MarketplacesService,
  PackageInfo,
  User,
} from "../../api-client";
import { Shipment } from "./Shipment";
import { useEffect, useState } from "react";
import { SendPackages } from "./SendPackages";
import { ShipmentTable } from "./ShipmentTable";
import { MdArrowBackIos } from "react-icons/md";
import { handleError } from "../../utils/functions";
import { columnsShipment } from "../../utils/columns";
import { useParams, useNavigate } from "react-router-dom";
import { format } from "path";

export const Trn = ({ userInfo }: { userInfo: User | undefined }) => {
  let params = useParams();
  const navigate = useNavigate();

  const [trn, setTrn] = useState<FbaInboundDocumentWithShipment | undefined>();
  const mpId = params.mpId;

  const trnId = params.trnId;
  const getTrn = async () => {
    if (!trnId) return;
    try {
      const response =
        await MarketplacesService.getMarketplacesAmazonFbaInboundTrnid({
          path: { trnId: Number(trnId) },
        });

      if (response.error) {
        const { error, errors } = response.error;
        if (error || errors) {
          handleError(response.error);
          return;
        }
      }

      setTrn(response.data?.data);
    } catch (error: any) {
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
  };

  useEffect(() => {
    if (!userInfo) return;
    getTrn();
  }, [userInfo]);

  const [packages, setPackages] = useState<(number | undefined)[]>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isNonPartnered, setIsNonPartnered] = useState<boolean>(false);

  const handleSendingPackage = (
    packagesToSend: PackageInfo[] | null | undefined,
    isNonPartnered: boolean
  ) => {
    const packagesArray = Array.isArray(packagesToSend)
      ? packagesToSend.map((packageItem) => packageItem.id)
      : [(packagesToSend as unknown as PackageInfo).id];
    setPackages(packagesArray);
    if (isNonPartnered) {
      setIsNonPartnered(true);
    }
    setShowModal(true);
  };

  const formatStatus = (status: string | undefined | null) => {
    if (!status) return "";
    return (
      status.charAt(0).toUpperCase() +
      status.slice(1).toLowerCase().replaceAll("_", " ")
    );
  };

  return (
    <div className="p-2 md:p-8 bg-gray-100 min-h-screen">
      <SendPackages
        showModal={showModal}
        setShowModal={setShowModal}
        packages={packages}
        setPackages={setPackages}
        isNonPartnered={isNonPartnered}
        setIsNonPartnered={setIsNonPartnered}
      />
      {trn && (
        <div>
          <div className="flex items-center gap-2 mb-6">
            <button onClick={() => navigate(`/?mpId=${mpId}`)}>
              <MdArrowBackIos size={30} />
            </button>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${userInfo?.organization?.appUrl}/wapp/en-gb/inventory/trn/${trn?.trnId}`}
              className="text-[35px] font-bold text-blue-600"
            >
              {trn?.trn}
            </a>
          </div>
          <div className="flex gap-5 flex-col md:flex-row">
            <div className="mb-6 p-4 bg-white shadow-md rounded-md md:w-[50%]">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-3xl font-semibold">TRN Info</h2>{" "}
                <p className="rounded-full border-[1px] border-[#4694b6] bg-[#4694b6] text-white px-2 w-fit shadow">
                  {formatStatus(trn.status)}
                </p>
              </div>
              <div className="flex justify-between mx-2">
                <div>
                  <p className="mb-2">
                    <span className="font-semibold">Packing Status: </span>
                    {formatStatus(trn.packingStatus)}
                  </p>
                  <p className="mb-2">
                    <span className="font-semibold">TRN Reference:</span>{" "}
                    {trn.trnReference}
                  </p>
                </div>
                {trn.date && (
                  <p className="mb-2 font-semibold">
                    {new Date(trn.date).toLocaleDateString()}
                  </p>
                )}
              </div>
            </div>
            <div className="mb-6 p-4 bg-white shadow-md rounded-md md:w-[50%]">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-3xl font-semibold">Inbound Info</h2>
                <p className="rounded-full border-[1px] border-[#4694b6] bg-[#4694b6] text-white px-2 w-fit shadow">
                  {formatStatus(trn.inboundInfo?.shipmentStatus)}
                </p>
              </div>
              <div className="flex justify-between mx-2">
                <div>
                  {trn.inboundInfo?.confirmedNeedByDate && (
                    <p className="mb-2">
                      <span className="font-semibold">Need By Date: </span>
                      {new Date(
                        trn.inboundInfo.confirmedNeedByDate
                      ).toLocaleDateString()}
                    </p>
                  )}
                  <p className="mb-2">
                    <span className="font-semibold">Box Contents Source: </span>
                    {trn.inboundInfo?.boxContentsSource?.replaceAll("_", " ")}
                  </p>
                </div>
                <div>
                  <p className="mb-2">
                    <span className="font-semibold">Are Cases Required: </span>
                    {trn.inboundInfo?.areCasesRequired ? "Yes" : "No"}
                  </p>
                  <p className="mb-2">
                    <span className="font-semibold">
                      Fulfillment Center ID:{" "}
                    </span>
                    {trn.inboundInfo?.fulfillmentCenterId}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {trn.shipments?.length && trn.shipments?.length > 0 ? (
            <div className="mb-6 p-4 bg-white shadow-md rounded-md">
              <div>
                <h2 className="text-3xl font-semibold mb-4">Shipments</h2>
              </div>
              {trn.shipments.map((shipment, index) => (
                <div key={index}>
                  <div className="flex items-center justify-between gap-4 mb-3 mx-4">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${userInfo?.organization?.appUrl}/wapp/en-gb/shp/${shipment.id}`}
                      className="text-2xl font-semibold text-blue-600"
                    >
                      SHP{shipment.id}
                    </a>
                    {!shipment.receiverNotified && (
                      <button
                        onClick={() =>
                          handleSendingPackage(
                            trn.shipments![index].packages,
                            true
                          )
                        }
                        className="px-3 py-1 bg-blue-600 hover:bg-blue-700 shadow-md text-white font-semibold rounded-lg transition duration-200 ease-in-out flex items-center gap-1.5"
                      >
                        Notify receiver
                      </button>
                    )}
                  </div>
                  <Shipment shipment={shipment} />
                </div>
              ))}
            </div>
          ) : null}
          {trn.pendingPackages?.length && trn.pendingPackages.length > 0 && (
            <div className="mb-6 p-4 bg-white shadow-md rounded-md">
              <div className="flex items-center justify-between mb-5">
                <h2 className="text-2xl font-semibold mb-2">
                  Pending Packages
                </h2>
                <button
                  onClick={() =>
                    handleSendingPackage(trn.pendingPackages, false)
                  }
                  className="border-[1px] bg-blue-500 text-white rounded-md font-semibold p-3 h-fit shadow"
                >
                  Request partnered transport
                </button>
              </div>
              <ShipmentTable
                data={trn.pendingPackages}
                columns={columnsShipment}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
