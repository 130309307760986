import { useState, FC, useEffect } from "react";
import {
  DataGrid,
  GridRowSelectionModel,
  GridPaginationModel,
  GridRowId,
} from "@mui/x-data-grid";
import { columnsSearch } from "../../utils/columns";
import { handleError } from "../../utils/functions";
import { MarketplacesService } from "../../api-client";
import { useTempTransferStore } from "../../store/tempTransfer";
import { AddProductsInterface, ShipmentSkuType} from "../../type/stepsType";

export const TableSearch: FC<AddProductsInterface> = ({
  setModalOpen,
  setSkus,
  skus,
}) => {
  const [rowsSelectedId, setRowsSelectedId] = useState<GridRowSelectionModel>(
    []
  );

  const [searchValue, setSearchValue] = useState<string>("");

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 50,
  });

  const { tempTransfer } = useTempTransferStore();

  const [skusAvailable, setSkusAvailable] = useState<ShipmentSkuType[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const onRowSelected = (ids: GridRowId[]) => {
    const addedIds = ids.filter((id) => !rowsSelectedId.includes(id));
    const removedIds = rowsSelectedId.filter((id) => !ids.includes(id));

    setRowsSelectedId(ids);
    const updatedSkus = skus.filter(
      (sku) => sku.mpSku && !removedIds.includes(sku.mpSku)
    );

    const newRowData = skusAvailable.filter(
      (row) => row.mpSku && addedIds.includes(row.mpSku)
    );
    updatedSkus.push(...newRowData);

    setSkus(updatedSkus);
  };

  const getData = async (newOffset = 0, search = "") => {
    if (skus.length) {
      const itemIds = skus
        .map((item) => item.mpSku)
        .filter((id): id is string => id !== null && id !== undefined);

      if (itemIds.length) {
        setRowsSelectedId(itemIds);
      }
    }
    if (!tempTransfer?.shipmentInfo) return;
    const { marketPlaceId } = tempTransfer.shipmentInfo.shipDestination;
    const { warehouseId } = tempTransfer.shipmentInfo.shipFrom;
    if (!marketPlaceId) {
      handleError({ error: "Marketplace or warehouse is missing" });
      return;
    }
    try {
      const response = await MarketplacesService.getMarketplacesMpidListings({
        path: {
          mpId: marketPlaceId,
        },
        query: {
          includeInventory: warehouseId,
          linked: "LINKED",
          fulfillment: "MARKETPLACE",
          offset: newOffset,
          search: search,
        },
      });
      setSkusAvailable(response.data?.results || []);
      setTotalCount(response.data?.totalCount || 0);
      setLoading(false);
    } catch (error: any) {
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
  };

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    const offset = newPaginationModel.page * newPaginationModel.pageSize;

    getData(offset);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getData(undefined, searchValue);
    }, 750);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchValue]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="fixed w-[100vw] h-[100vh] bg-black bg-opacity-50 top-0 left-0 z-[20]">
      <div className="fixed w-[90vw] min-h-[85vh] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 bg-white rounded-sm px-4 pt-4">
        <h3 className="text-[25px] mb-2">Add products</h3>
        <div>
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only"
          >
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block sm:w-[300px] p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Search by SKU or product name"
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-5">
          <div className="h-[69vh]">
            <DataGrid
              getRowId={(product) => product.mpSku}
              rows={skusAvailable}
              columns={columnsSearch}
              loading={loading}
              rowCount={totalCount}
              disableColumnSelector={true}
              checkboxSelection
              keepNonExistentRowsSelected
              paginationMode="server"
              onPaginationModelChange={handlePaginationModelChange}
              disableDensitySelector={true}
              paginationModel={paginationModel}
              onRowSelectionModelChange={(
                newRowSelectionModel: GridRowId[]
              ) => {
                onRowSelected(newRowSelectionModel);
              }}
              rowSelectionModel={rowsSelectedId}
              pageSizeOptions={[50]}
            />
          </div>
          <div className="flex gap-4 justify-end items-center my-2">
            <button
              onClick={() => setModalOpen(false)}
              className="p-2 px-3 bg-[#008297] hover:bg-[#006f7c] text-white font-semibold rounded shadow-md disabled:bg-gray-400"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
